/* You may have a few 'require' lines above this already */


$(document).on("turbolinks:load", function() {
  gtag('config', 'G-VE058VGYEM', {'page_location': event.data.url});
})

require("@popperjs/core")

import "bootstrap"

// require('jquery')

// Import the specific modules you may need (Modal, Alert, etc)
import { Tooltip, Popover } from "bootstrap"

// The stylesheet location we created earlier
require("../stylesheets/application.scss")

// If you're using Turbolinks. Otherwise simply use: jQuery(function () {
document.addEventListener("turbolinks:load", () => {
    // Both of these are from the Bootstrap 5 docs
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function(tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl)
    })

    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function(popoverTriggerEl) {
        return new Popover(popoverTriggerEl)
    })
})

$(document).ready(function(){

	$( "#results-form" ).submit(function( event ) {

    var url = $(this).attr("action")
    var data = $(this).serialize();
    var $btn = $(this).find(':submit');
    $btn.attr("disabled", true);

 		$.ajax({
      url: url,
      headers: {
        Accept : "text/javascript; charset=utf-8",
        "Content-Type": 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      type: 'POST',
      data: data,
      beforeSend: function(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));

      },
      error: function(xhr, status, error) {
        console.log("error");
      },
      complete: function() {
        console.log("done");
    		$btn.attr("disabled", false);
      },
      success: function() {
        var position = $("#results").position();
        window.scroll({
 	 				top: position.top,
  				left: 0,
  				behavior: 'smooth'
				});
      }
    });

  	event.preventDefault();

	});

	
});